import React, { useEffect } from "react";

import Hero from "@components/shared/hero";
import LogoSlider from "@components/shared/slider/logoSlider";
import Features from "@components/shared/features";
import VideoBlock from "@components/shared/sub/videoBlock";
import LeftRightBlock from "@components/shared/sub/leftRightBlock";
import LandingNote from "@components/landingNote";

//Location context
import { useCovidBannerQuery, useCustomerLogosQuery } from "@api";
import SEO from "@components/shared/seo";
import LayOut from "@components/layout";
import { graphql, PageProps } from "gatsby";
import { Query, SanityHomePageConnection } from "@graphql-types";
import { useRegionChanged } from "@util/hooks";
import { useStore } from "@state/store";
import { Container } from "@util/standard";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "@util/constants";
import alertImage from "./../../static/assets/alert.png";

interface Data extends Query {
  altRegions: SanityHomePageConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const StyledA = styled.a<{ noLink?: boolean }>`
  background-color: #49a9fc;
  color: white;
  padding: 10px;
  padding-inline: 40px;
  border-radius: 20px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  font-family: header bold;

  &:hover {
    color: white;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    text-align: center;
    align-self: center;
  }
`;

const Home = ({ data, pageContext }: Props) => {
  const { sanityTopBar } = useCovidBannerQuery();
  const { nodes } = data.allSanityHomePage;
  const sanityHomePage = nodes[0];

  const alternateRegions = data.altRegions.nodes.map(alt => alt._rawRegion);

  const { region } = useStore();
  const filteredLogos = useCustomerLogosQuery(region.iban);
  const filtered = data?.sanityUseApprovedsIndex?._rawLogosArray?.filter(
    logoBlock =>
      logoBlock?.locationsArray
        ?.map(location => location?.value)
        .includes(pageContext.iban)
  );

  useRegionChanged("", true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    homeHero,
    _rawComplianceLogos,
    features,
    homeVideoBlock,
    homeLeftRightBlock,
  } = sanityHomePage;

  // Sort the hero for location

  return (
    <>
      <SEO
        seoData={sanityHomePage.seo}
        alternateRegions={alternateRegions}
        slug={pageContext.slug}
      />
      <LayOut>
        <Hero heroContent={homeHero} featuredHero />
        {filteredLogos && (
          <LogoSlider
            sliderHeader={"Loved by healthcare teams"}
            customerLogos={filteredLogos.logos}
            sliderButton={{
              linktext: "Our customers",
              url: "/customers",
            }}
            isButton
          />
        )}
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          margin="75px 0 0 0"
          mobileMargin="0 0 20px 0"
          tabletMargin="0 0 40px 0"
        >
          <Container
            style={{
              display: "flex",
              alignItems: "center",
              padding: "30px",
              paddingInline: "50px",
              backgroundColor: "rgba(73,169,252,0.2)",
              borderRadius: "20px",
              gap: "30px",
            }}
            mobileWidth="100%"
            mobileStyling="flex-direction: column;"
            maxWidth="1000px"
          >
            <div style={{ flex: 1, display: 'flex', justifyContent: "center" }}>
            <a href="https://hello.celohealth.com/communication-calculator" target="_blank" style={{textAlign: "center"}}>
              <img
                src={alertImage}
                width="275px"
              />
            </a>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <h3>Celo's Communication Calculator</h3>
              <span>Use our calculator to generate a communication snapshot and discover how to unlock communication efficiency in your organization.</span>
              <StyledA
                href="https://hello.celohealth.com/communication-calculator"
                target="_blank"
              >
                Try it now
              </StyledA>
            </div>
          </Container>
        </Container>

        <LandingNote />

        {homeVideoBlock && (
          <VideoBlock
            title={homeVideoBlock.videoHeader}
            description={homeVideoBlock.videoDescription}
            videoUrl={homeVideoBlock.videoUrl}
            thumbnail={homeVideoBlock.videoThumbnail}
            hasThumbnail
          />
        )}
        <Features content={features} />
        <LeftRightBlock content={homeLeftRightBlock} />
        <LogoSlider
          sliderHeader={_rawComplianceLogos?.sliderHeader}
          sliderButton={_rawComplianceLogos?.sliderLink}
          logos={filtered && filtered[0]}
          isButton
        />
      </LayOut>
    </>
  );
};

export default Home;

export const query = graphql`
  query HomeQuery($iban: String) {
    allSanityHomePage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        homeHero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        features {
          title
          features {
            _key
            title
            description
            cover
            Image {
              ...sanityImageWithMeta
            }
          }
        }
        homeVideoBlock {
          videoHeader
          videoDescription
          videoUrl
          videoThumbnail {
            ...sanityImageWithMeta
          }
        }
        homeLeftRightBlock {
          alignment
          blockImage {
            ...sanityImageWithMeta
          }
          blockText {
            _rawContent
          }
        }
        _rawComplianceLogos(resolveReferences: { maxDepth: 5 })
        complianceLogos {
          sliderHeader
          sliderContent
          sliderLink {
            ...sanityLink
          }
        }
      }
    }
    altRegions: allSanityHomePage {
      nodes {
        _rawRegion(resolveReferences: { maxDepth: 10 })
      }
    }
    sanityUseApprovedsIndex {
      _rawLogosArray(resolveReferences: { maxDepth: 5 })
      logosArray {
        _key
        locationsArray {
          _key
          name
          value
        }
        title
        logos {
          _key
          title
          image {
            altText
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 150)
            }
          }
        }
      }
    }
  }
`;
