import React from "react";
import styled from "styled-components";

import { default as GlobalImage } from "@components/shared/sub/image";

import {
  Container,
  GridContainer,
  P,
  MenuButtonGatsbyLink,
} from "@util/standard";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Maybe, SanityFeatures } from "@graphql-types";

interface Props {
  content: Maybe<SanityFeatures> | undefined;
}

interface FeatureCardProps {
  featureContent: any;
}

const Title = styled.h2`
  text-align: center;
  padding-top: 70px;
  padding-bottom: 30px;
  color: black;
  margin: 0px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 0 auto;
    width: 95%;
  }
`;

const FeatureCardWrapper = styled(Container)`
  border-radius: 12px;
  background-color: white;
  color: black;
  @media only screen and (max-width: 1200px) {
    width: 95%;
  }
`;

const ImageWrapper = styled(Container)`
  height: 266px;
  width: 100%;
  padding: 20px;
  padding-bottom: 0px;
  margin-bottom: 15px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 250px;
    width: 250px;
    margin: 20px auto;
    padding: 0px;
  }
`;

const CardTitle = styled.h3`
  text-align: center;
  width: 70%;
  margin: 5px auto;
  color: black;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 10px auto;
    width: 100%;
  }
`;

function Features(props: Props) {
  const { content } = props;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{}'.
  const { title, features } = content;
  return (
    <Container
      backgroundColor="babyBlue"
      margin="100px auto"
      mobileMargin="40px auto 50px auto"
      textAlign="center"
    >
      <Title>{title}</Title>

      <GridContainer
        width="97%"
        mobileWidth="95%"
        maxWidth={1140}
        margin="0 auto"
        rowGap={18}
        mobileRepeat={1}
        tabletRepeat={2}
      >
        {features.map(feature => {
          return <FeatureCard featureContent={feature} key={feature._key} />;
        })}
      </GridContainer>
      <Container margin="40px auto" display="inline-block" width="100%">
        <MenuButtonGatsbyLink
          to="/features"
          width="fit-content"
          padding="10px 25px 10px 25px"
          isPink
        >
          See more features
        </MenuButtonGatsbyLink>
      </Container>
    </Container>
  );
}

const FeatureCard = (props: FeatureCardProps) => {
  const { featureContent } = props;

  const { Image, description, title } = featureContent;

  return (
    <FeatureCardWrapper
      width="90%"
      margin="0px auto 0px auto"
      height="auto"
      tabletHeight="100%"
      tabletWidth="252px"
    >
      <ImageWrapper>{Image && <GlobalImage data={Image} />}</ImageWrapper>
      <CardTitle>{title}</CardTitle>
      <P
        textAlign="center"
        width="86.1%"
        tabletWidth="80%"
        margin="0px auto 25px auto"
        color="black"
      >
        {description}
      </P>
    </FeatureCardWrapper>
  );
};

export default Features;
