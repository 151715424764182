import React from "react";
import styled from "styled-components";

import Image from "@components/shared/sub/image";
import { Container, GridContainer } from "@util/standard";
import BlocksContent from "./blockContent";
import { icons, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Maybe, SanityLeftRightBlock } from "@graphql-types";

interface Props {
  arrowVisible?: boolean;
  content: Maybe<SanityLeftRightBlock> | undefined;
}

const ImageWrapper = styled.div`
  height: 426px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 242px;
    margin-bottom: 30px;
  }
`;

const StyledImage = styled(Image)`
  width: 100% !important;
  height: 100% !important;

  img {
    object-fit: contain !important; // !important to override the element style
  }
`;

const TextWrapper = styled.div`
  margin: auto;
  width: 65%;

  h2 {
    width: 90%;
    color: black;
    max-width: 320px;
    min-width: 320px;
  }

  p {
    color: black;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    text-align: center;

    h2 {
      margin-top: 50px;
      width: 100%;
      max-width: 100%;
    }
    a {
      margin: auto;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 60px;
    width: 100%;
    h2 {
      margin-top: 0px;
    }

    p {
      width: 95%;
      margin: 0 auto 15px auto;
    }
  }
`;

function LeftRightBlock(props: Props) {
  // @ts-expect-error ts-migrate(6133) FIXME: 'arrowVisible' is declared but its value is never ... Remove this comment to see the full error message
  const { content, arrowVisible } = props;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'blockImage' does not exist on type '{}'.
  const { blockImage, blockText, alignment } = content;

  return (
    <Container position="relative" maxWidth="1200px" margin="auto">
      <GridContainer
        gridTemplateColumns="repeat(2,1fr)"
        width="90%"
        margin="0 auto"
        tabletRepeat={1}
        mobileRepeat={1}
      >
        <ImageWrapper>
          <StyledImage data={blockImage} height="100%" />
        </ImageWrapper>
        <TextWrapper>
          <BlocksContent blocks={blockText._rawContent} />
        </TextWrapper>
      </GridContainer>
    </Container>
  );
}

export default LeftRightBlock;
